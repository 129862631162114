import $ from 'jquery'

import WOW from 'wow/wow.min.js'

window.addEventListener('DOMContentLoaded', () => {
  $("[rel='tooltip']").tooltip({
    trigger : 'hover'
  });
  $('body').scrollspy({
    target: '#navbar',
    offset: 80
  });

  $('a.page-scroll').bind('click', function (event) {
    var link = $(this);
    $('html, body').stop().animate({
       scrollTop: $(link.attr('href')).offset().top - 50
    }, 500);
    event.preventDefault();
    $("#navbar").collapse('hide');
  });

  var cbpAnimatedHeader = (function () {
    var docElem = document.documentElement,
      header = document.querySelector('.navbar-default'),
      didScroll = false,
      changeHeaderOn = 5;

    function init() {
      window.addEventListener('scroll', function (event) {
        if (!didScroll) {
          didScroll = true;
          setTimeout(scrollPage, 250);
        }
      }, false);
    }

    function scrollPage() {
      var sy = scrollY();
      if (sy >= changeHeaderOn) {
         $(header).addClass('navbar-scroll')
      } else {
         $(header).removeClass('navbar-scroll')
      }
      didScroll = false;
    }

    function scrollY() {
      return window.pageYOffset || docElem.scrollTop;
    }
    init();

  })();

  $('ul.nav li.dropdown').hover(function () {
    $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
  }, function () {
    $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
  });

  $(".progress").each(function () {
    var value = $(this).attr('data-value');
    var left = $(this).find('.progress-left .progress-bar');
    var right = $(this).find('.progress-right .progress-bar');

    if (value > 0) {
      if (value <= 50) {
         right.css('transform', 'rotate(' + value + 'deg)')
      } else {
         right.css('transform', 'rotate(180deg)')
         left.css('transform', 'rotate(' + value - 50 + 'deg)')
      }
    }
  })

  function percentageToDegrees(percentage) {
    return percentage / 100 * 360
  }

  var mybutton = document.getElementById("myBtn");

  window.onscroll = function () {
    scrollFunction()
  };

  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  window.wow = new WOW.WOW({
    live: false
  });
  window.wow.init();

  var elem = document.querySelector(".js-switch");
  if (elem) {
    var switchery = new Switchery(elem, {
       color: '#e87c26',
       jackColor: '#fff'
    });
  }
})

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("jquery")
require("jquery-ui/jquery-ui.min")
require("bootstrap/dist/js/bootstrap")
require("bootstrap/bootstrap.min")
require("metismenu/jquery.metisMenu")
require("slimScroll/jquery.slimscroll.min")
require("inspinia/inspinia")
require("pace")
require("wow/wow.min")

import "custom/custom"
window.jQuery = $;
window.$ = $;
